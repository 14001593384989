import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import { useAuthUser } from '@xylabs/sdk-coin-react/auth'
import { FlexGrowCol, FlexGrowPaper, FlexRow, MessageDialog } from '@xyo-network/sdk-xyo-react-js'
import { DateTime } from 'luxon'
import { useState } from 'react'
import JsonView from 'react-json-view'

import BasePage from '../../BasePage'
import { useUserInfo, useUserStatsXyo } from '../Hooks'

const Json: React.FC = () => {
  const user = useAuthUser()

  const [sanitize, setSanitize] = useState(false)

  const { userInfo } = useUserInfo(sanitize)

  const end = DateTime.now()
  const start = end.minus({ days: 7 })

  const { statsXyo, error } = useUserStatsXyo(
    parseInt(start.toFormat('yyyyMMdd')),
    parseInt(end.toFormat('yyyyMMdd')),
    sanitize
  )

  const breadcrumbs = [user?.displayName ?? 'User', 'COIN', 'Json']

  return (
    <BasePage authOnly devOnly container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol padding={2} justifyContent="flex-start" alignItems="stretch">
        {userInfo?._admin ? (
          <FlexRow>
            <FormControlLabel
              control={
                <Switch
                  checked={sanitize}
                  onChange={(event) => {
                    setSanitize(event.target.checked)
                  }}
                  name="sanitize"
                  color="primary"
                />
              }
              label="Sanitize"
            />
          </FlexRow>
        ) : null}
        <FlexGrowPaper padding={2} busy={!userInfo}>
          {userInfo ? (
            <JsonView
              sortKeys
              collapseStringsAfterLength={96}
              src={{ statsXyo, userInfo }}
              style={{ overflow: 'hidden', width: '100%' }}
            />
          ) : null}
        </FlexGrowPaper>
      </FlexGrowCol>
      <MessageDialog title={error?.message ?? 'Error'} open={!!error}>
        <Typography>Oops. Something went wrong. Please try again in a few minutes.</Typography>
      </MessageDialog>
    </BasePage>
  )
}

export default Json
