import { CoinDashboard, useAuthUser } from '@xylabs/sdk-coin-react/auth'
import { FlexGrowCol } from '@xyo-network/sdk-xyo-react-js'

import { CoinUserProvider, SweepstakesSummaryProvider, UserStatsContextProvider } from '../../../Contexts'
import BasePage from '../../BasePage'

const Page: React.FC = () => {
  const user = useAuthUser()

  const breadcrumbs = [user?.displayName ?? 'User', 'COIN']

  return (
    <BasePage beta authOnly container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol alignItems="stretch" justifyContent="flex-start" marginY={2}>
        <CoinUserProvider>
          <SweepstakesSummaryProvider>
            <UserStatsContextProvider>
              <CoinDashboard />
            </UserStatsContextProvider>
          </SweepstakesSummaryProvider>
        </CoinUserProvider>
      </FlexGrowCol>
    </BasePage>
  )
}

export default Page
