import { CoinUserInfo, UserContext } from '@xylabs/sdk-coin-react'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { PropsWithChildren, useState } from 'react'

import { useApi } from './Api'

interface Props {
  sanitize?: boolean
}

const Provider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { sanitize = true } = props
  const [userInfo, setUserInfo] = useState<CoinUserInfo>()
  const [refreshCount, setRefreshCount] = useState(0)
  const [error, setError] = useState<Error>()
  const api = useApi()

  const getUser = async () => {
    return sanitize ? await api?.getUserSanitized() : await api?.getUser()
  }

  const fetchUser = async () => {
    return sanitize ? await api?.fetchUserSanitized() : await api?.fetchUser()
  }

  useAsyncEffect(
    async (mounted) => {
      if (mounted()) {
        setUserInfo(undefined)
        setError(undefined)
      }
      if (api && api.authenticated) {
        try {
          const userInfo = refreshCount === 0 ? await getUser() : await fetchUser()
          if (mounted()) {
            setUserInfo(userInfo)
          }
        } catch (ex) {
          if (mounted()) {
            setError(ex)
          }
        }
      }
    },
    [api, refreshCount]
  )

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
  }

  return <UserContext.Provider value={{ error, refresh, userInfo }} {...props} />
}

export default Provider
