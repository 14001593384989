import { Container, Typography } from '@material-ui/core'
import { useAuth } from '@xylabs/sdk-coin-react/auth'
import { ButtonEx, FlexGrowCol, FlexGrowRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import BasePage from '../BasePage'

const SignoutPage: React.FC = () => {
  const auth = useAuth()

  auth?.signOut()

  return (
    <BasePage>
      <FlexGrowRow>
        <Container maxWidth="md">
          <FlexGrowCol alignItems="center">
            <Typography variant="h1">You have been signed out</Typography>
            <ButtonEx marginY={2} color="secondary" variant="contained" to="/account/signin">
              Sign back into COIN
            </ButtonEx>
          </FlexGrowCol>
        </Container>
      </FlexGrowRow>
    </BasePage>
  )
}

export default SignoutPage
