import { Stepper } from '@material-ui/core'
import { useUserEvents } from '@xylabs/sdk-coin-react'
import React, { useState } from 'react'

import { BuyTicketsStep, EmailStep, InstallStep, PasswordStep, ShareStep } from './Steps'

interface Props {
  buytickets?: boolean
  onDone?: () => void
  optionsId: string
  share?: boolean
}

enum Steps {
  Email = 0,
  Password = 1,
  BumpOffer = 2,
  Install = 3,
  Done = 4,
}

const FunnelStepper: React.FC<Props> = (props) => {
  const { onDone, optionsId, share, buytickets } = props

  const [activeStep, setActiveStep] = useState(0)
  const [email, setEmail] = useState<string>()
  const userEvents = useUserEvents()

  const onEmailContinue = (email: string) => {
    userEvents.emailCollected({ email })
    setEmail(email)
    setActiveStep(Steps.Password)
  }

  const onPasswordBack = () => {
    setActiveStep(Steps.Email)
  }

  const onPasswordContinue = () => {
    setActiveStep(Steps.BumpOffer)
  }

  const onShareContinue = () => {
    userEvents.shareCompleted()
    setActiveStep(Steps.Install)
  }

  const onBuyTicketsContinue = () => {
    userEvents.shareCompleted()
    setActiveStep(Steps.Install)
  }

  const onInstallContinue = () => {
    userEvents.installCompleted()
    userEvents.funnelCompleted()
    setActiveStep(Steps.Done)
    onDone?.()
  }

  return (
    <Stepper activeStep={activeStep} orientation="vertical" style={{ flexGrow: 1 }}>
      <EmailStep onContinue={onEmailContinue} stepName="Step 1" subtitle="Where should we send an email if you win?" />
      <PasswordStep
        email={email}
        onContinue={onPasswordContinue}
        onBack={onPasswordBack}
        stepName="Step 2"
        subtitle="What password do you want to use to access your entries?"
      />

      {share ? (
        <ShareStep onContinue={onShareContinue} stepName="Step 3" subtitle="How many free entries do you want?" />
      ) : null}
      {buytickets ? (
        <BuyTicketsStep
          onContinue={onBuyTicketsContinue}
          optionsId={optionsId}
          stepName="Step 3"
          subtitle="How many entries do you want?"
          email={email}
        />
      ) : null}
      <InstallStep
        onContinue={onInstallContinue}
        stepName="Collect"
        subtitle="Awesome, download COIN here to access your free entries!"
        link="https://coin.onelink.me/ePJg/262cfa7c"
      />
    </Stepper>
  )
}

export default FunnelStepper
