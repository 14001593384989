import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import { useUserEvents } from '@xylabs/sdk-coin-react'
import { FlexRow } from '@xyo-network/sdk-xyo-react-js'
import { useHistory } from 'react-router-dom'

import genericImage from '../../../img/sweepstakes/generic/320x180.jpg'
import OfferProps from '../../../OfferProps'
import EndTimer from '../EndTimer'
import Tag from './Tag'

const OfferCard: React.FC<OfferProps> = (props) => {
  const {
    current,
    winner,
    winnerImage,
    tags,
    id,
    href,
    to,
    title,
    endTime,
    description,
    icon,
    image,
    style,
    claimButtonText,
    ...cardProps
  } = props
  const theme = useTheme()
  const userEvents = useUserEvents()
  const history = useHistory()

  const onClaimClick = () => {
    userEvents.offerRequested({ id })
    if (to) {
      history.push(to)
    } else {
      window.open(href)
    }
  }

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '400px',
        position: 'relative',
        width: '320px',
        ...style,
      }}
      {...cardProps}
    >
      <FlexRow position="absolute" top={8} left={8}>
        {tags?.map((tag) => (
          <Tag key={tag} tag={tag} />
        ))}
      </FlexRow>
      <CardMedia
        image={image?.landscape?.image ?? genericImage}
        title={title}
        style={{ height: 0, paddingTop: '56.25%' }}
      />
      <CardHeader
        avatar={
          <Avatar
            aria-label="offer"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.getContrastText(theme.palette.secondary.dark),
            }}
          >
            {icon}
          </Avatar>
        }
        title={
          <FlexRow justifyContent="flex-start">
            <Typography variant="h6" noWrap style={{ textOverflow: 'ellipsis' }}>
              {title}
            </Typography>
          </FlexRow>
        }
        subheader={
          <FlexRow justifyContent="flex-start">
            {endTime ? (
              <EndTimer endTime={endTime} />
            ) : (
              <Typography noWrap={true} variant="subtitle2">
                Currently Available
              </Typography>
            )}
          </FlexRow>
        }
      />
      <CardContent style={{ paddingBottom: theme.spacing(0.5), paddingTop: theme.spacing(0.5) }}>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions
        disableSpacing={true}
        style={{
          bottom: 0,
          display: 'flex',
          justifyContent: 'space-between',
          padding: 0,
          position: 'absolute',
          width: '100%',
        }}
      >
        <Toolbar>
          <IconButton size="small" aria-label="share">
            <ShareIcon />
          </IconButton>
        </Toolbar>
        <Toolbar>
          {current ? (
            <Button fullWidth variant="outlined" color="secondary" onClick={onClaimClick}>
              {claimButtonText ?? 'Claim'}
            </Button>
          ) : (
            <FlexRow>
              <Typography variant="caption">{`Winner: ${winner}`}</Typography>
              {winnerImage ? (
                <FlexRow margin={1} border={1} borderRadius={theme.shape.borderRadius} overflow="hidden">
                  <img height={32} width={32} src={winnerImage} />
                </FlexRow>
              ) : null}
            </FlexRow>
          )}
        </Toolbar>
      </CardActions>
    </Card>
  )
}

export default OfferCard
