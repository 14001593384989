import 'firebase/auth'

import { AuthContext } from '@xylabs/sdk-coin-react/auth'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import firebase from 'firebase/app'
import React, { PropsWithChildren, useState } from 'react'

import { Authentication } from '../../lib'

interface Props {
  options?: Record<string, unknown>
}

const defaultOptions = {
  apiKey: 'AIzaSyBTEc9lznb8EnpyVfXD5XoZDT4ETeBVqpU',
  appId: '1:542704523793:web:3a29a240bd947c0bae6fb0',
  authDomain: 'xyo-network-1522800011804.firebaseapp.com',
  databaseURL: 'https://xyo-network-1522800011804.firebaseio.com',
  measurementId: 'G-VM9QNYBKCT',
  messagingSenderId: '542704523793',
  projectId: 'xyo-network-1522800011804',
  storageBucket: 'xyo-network-1522800011804.appspot.com',
}

const Provider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { options, children } = props
  const [auth, setAuth] = useState<Authentication>()
  const [user, setUser] = useState<firebase.User | null>()
  const [authenticated, setAuthenticated] = useState<boolean>()

  useAsyncEffect(
    async (mounted) => {
      let newAuth: Authentication | undefined = undefined
      const onAuthStateChanged = (user?: firebase.User | null) => {
        if (mounted()) {
          setAuthenticated(!!newAuth?.authenticated)
          setUser(newAuth?.authenticated ? user : null)
        }
      }

      newAuth = await Authentication.create('coin', { ...defaultOptions, ...options }, onAuthStateChanged)
      if (mounted()) {
        setAuth(newAuth)
      }
      return () => {
        //newAuth?.close()
      }
    },
    [options]
  )

  return (
    <AuthContext.Provider value={{ auth: auth?.auth, authenticated, user }}>
      {auth != undefined ? children : null}
    </AuthContext.Provider>
  )
}

export default Provider
