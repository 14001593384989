import { Typography } from '@material-ui/core'
import { CoinSweepstakeJson } from '@xylabs/sdk-coin-js'
import { FlexGrowCol, FlexRow, useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import React, { useState } from 'react'

import { useApi } from '../../Contexts'
import BasePage from '../BasePage'
import offersFromSweepstakes from '../offersFromSweepstakes'
import { JsonViewer } from './Components'

const JsonPage: React.FC = () => {
  const api = useApi()
  const [sweepstakes, setSweepstakes] = useState<CoinSweepstakeJson[]>()

  useAsyncEffect(
    async (mounted) => {
      if (api) {
        const sweepstakes = await api.getSweepstakes()
        if (mounted()) {
          setSweepstakes(sweepstakes)
        }
      }
    },
    [api]
  )

  const offers = sweepstakes ? offersFromSweepstakes(sweepstakes, false) : undefined

  const breadcrumbs = ['Offers', 'Json']

  return (
    <BasePage title="Offers Json" devOnlyTo="/" localAsDev container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol marginY={2} alignItems="stretch">
        <FlexRow justifyContent="flex-start" marginY={1}>
          <Typography variant="h4">Available Offers Json</Typography>
        </FlexRow>
        <FlexGrowCol alignItems="stretch" marginTop={2} busy={!offers}>
          {offers ? <JsonViewer open={!!offers} json={{ ...offers }} /> : null}
        </FlexGrowCol>
      </FlexGrowCol>
    </BasePage>
  )
}

export default JsonPage
