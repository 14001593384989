import { Card, CardMedia } from '@material-ui/core'
import { StatCardHeader, StatCardSummary } from '@xylabs/sdk-coin-react'
import { FlexCol } from '@xyo-network/sdk-xyo-react-js'
import { DateTime } from 'luxon'

import sweepstakesImage from '../../../img/features/sweepstakes.svg'
import genericImage from '../../../img/sweepstakes/generic/320x180.jpg'
import SkuToImageSet from '../../../SkuToImageSet'
import ActiveCardActions from './ActiveCardActions'
import EndTimer from './EndTimer'
import InactiveCardActions from './InactiveCardActions'
import OfferProps from './SweepstakeProps'

const SweepstakeCard: React.FC<OfferProps> = (props) => {
  const { sweepstake, style, ...cardProps } = props
  const { details } = sweepstake ?? {}

  const imageSet = details?.sku ? SkuToImageSet[details?.sku] : null

  const endTime = details?.endDate ? DateTime.fromISO(details?.endDate) : null
  const inactive = details?.inactive

  return (
    <Card
      style={{
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        width: '320px',
        ...style,
      }}
      {...cardProps}
    >
      <CardMedia
        image={imageSet?.landscape?.image ?? genericImage}
        title={details?.title}
        style={{ height: 0, paddingTop: '56.25%' }}
      />
      <FlexCol
        style={{
          backgroundImage: `url(${sweepstakesImage})`,
          backgroundPositionX: 'right',
          backgroundPositionY: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 300,
        }}
        alignItems="stretch"
      >
        <StatCardHeader
          title={details?.title}
          subtitle={endTime ? <EndTimer endTime={endTime} /> : 'Currently Available'}
        />
        <StatCardSummary
          style={{ borderBottom: '1px solid gray' }}
          value={sweepstake?.entries}
          title="Your Entries"
          format="0,0a"
        />
      </FlexCol>
      {inactive ? <InactiveCardActions sweepstake={sweepstake} /> : <ActiveCardActions sweepstake={sweepstake} />}
    </Card>
  )
}

export default SweepstakeCard
