import { BoxProps, Typography } from '@material-ui/core'
import { FlexCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

const BottomMessage: React.FC<BoxProps> = (props) => {
  return (
    <FlexCol alignItems="flex-start" {...props}>
      <FlexRow margin={2} justifyContent="flex-start">
        <Typography variant="subtitle1">
          After this page, you&apos;ll get easy instructions for how to download the app, log in, and assign your free
          entries to the Playstation 5 Sweepstakes.
        </Typography>
      </FlexRow>
      <FlexRow margin={2} justifyContent="flex-start">
        <Typography variant="subtitle1">
          If you see other sweepstakes you like, you can even split up your entries between your favorite items!
        </Typography>
      </FlexRow>
    </FlexCol>
  )
}

export default BottomMessage
