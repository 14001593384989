import { Button, Step, StepContent, StepLabel, StepProps, Typography } from '@material-ui/core'
import { assertEx } from '@xyo-network/sdk-xyo-js'
import { FlexCol, FlexGrowCol, FlexRow } from '@xyo-network/sdk-xyo-react-js'
import React from 'react'

import AccountSource from '../../AccountSource'

interface Props extends StepProps {
  onContinue?: () => void
  score?: number
  source?: AccountSource
  stepName?: string
  subtitle?: string
}

const CustomStep: React.FC<Props> = (props) => {
  const { onContinue, stepName, subtitle, score, source, ...stepProps } = props

  const sourceButtonData: Record<AccountSource, { href: string; label: string }> = {
    [AccountSource.FacebookAd]: {
      href: 'https://forms.gle/TEzhQYdFzuUC147y7',
      label: 'Facebook Review (2000 COIN)',
    },
    [AccountSource.PlayStore]: {
      href: 'https://forms.gle/cAPTyMpCy3wJfbhv8',
      label: 'PlayStore Review (2000 COIN)',
    },
    [AccountSource.AppStore]: {
      href: 'https://forms.gle/6nfyFN68npE5JcCFA',
      label: 'AppStore Review (2000 COIN)',
    },
    [AccountSource.Other]: {
      href: 'https://forms.gle/gFpajCjNWsCSzDSo8',
      label: 'Tell Us More (500 COIN)',
    },
    [AccountSource.GoogleAd]: {
      href: 'https://forms.gle/gFpajCjNWsCSzDSo8',
      label: 'Tell Us More (500 COIN)',
    },
    [AccountSource.OtherAd]: {
      href: 'https://forms.gle/gFpajCjNWsCSzDSo8',
      label: 'Tell Us More (500 COIN)',
    },
    [AccountSource.Friend]: {
      href: 'https://forms.gle/gFpajCjNWsCSzDSo8',
      label: 'Tell Us More (500 COIN)',
    },
  }

  return (
    <Step {...stepProps}>
      <StepLabel>
        <Typography variant="h4">{stepName}</Typography>
      </StepLabel>
      <StepContent>
        <FlexGrowCol alignItems="stretch" paddingY={1}>
          <Typography variant="body1">{subtitle}</Typography>
          {score !== 5 ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              href="https://forms.gle/WnjToMLm6sGMVKkY7"
              onClick={onContinue}
            >
              Tell us how we can do better (500 COIN)
            </Button>
          ) : null}
          {score === 5 ? (
            <FlexCol>
              {source ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  href={assertEx(sourceButtonData[source].href)}
                  onClick={onContinue}
                >
                  {assertEx(sourceButtonData[source].label)}
                </Button>
              ) : null}
              <FlexRow>
                <Typography variant="body1">We are so happy that you are enjoying COIN!</Typography>
              </FlexRow>
            </FlexCol>
          ) : null}
        </FlexGrowCol>
      </StepContent>
    </Step>
  )
}

export default CustomStep
