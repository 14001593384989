import { useAuthUser } from '@xylabs/sdk-coin-react/auth'
import { FlexGrowCol } from '@xyo-network/sdk-xyo-react-js'

import BasePage from '../../BasePage'

const Page: React.FC = () => {
  const user = useAuthUser()

  const breadcrumbs = [user?.displayName ?? 'User', 'COIN', 'Stats']

  return (
    <BasePage beta authOnly container="xl" breadcrumbs={breadcrumbs}>
      <FlexGrowCol alignItems="stretch" justifyContent="flex-start" marginY={2}></FlexGrowCol>
    </BasePage>
  )
}

export default Page
