import { useAuthUser } from '@xylabs/sdk-coin-react/auth'
import { useAsyncEffect } from '@xyo-network/sdk-xyo-react-js'
import { useState } from 'react'

const useAuthToken = (forceRefresh = false) => {
  const [token, setToken] = useState<string>()

  const user = useAuthUser()

  console.log(`useAuthToken: ${user}`)

  useAsyncEffect(
    async (mounted) => {
      const token = await user?.getIdToken(forceRefresh)
      if (mounted()) {
        setToken(token)
      }
    },
    [user]
  )

  return token
}

export default useAuthToken
